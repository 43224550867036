.App {
  background-image: url('./DonaChida.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.letra {
  transition: color 1s ease-in-out;
}
.letra:hover {
  color: rgba(255, 255, 255, 1);
}